<app-page>
  <page-header></page-header>
  <page-content>
    <div id="friends-schools" class="my-5">
      <div class="container">
        <div class="countries">
          <div
            *ngFor="let country of countries"
            (click)="openModal(country)"
            class="single-country cursor-pointer"
          >
            <div class="img-wrapper">
              <img
                [src]="country.image"
                class="rounded-2 cover d-block mb-3 country-image hover-shadow"
                style="width: 100%; aspect-ratio: 1"
                [alt]="country.nameAr"
              />
              <div class="flag-wrapper">
                <img src="assets/images/flag-wrapper.svg" alt="" class="mask" />
                <img [src]="country.logo" class="flag" [alt]="country.nameAr" />
              </div>
            </div>
            <p class="fw-bold small text-center mt-4">
              {{ country.nameAr }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
