import { gql } from "apollo-angular";
import { ContactUsPage } from "../interfaces/contact-us-page";

export const GET_CONTACT_US_PAGE = gql<{ page: ContactUsPage }, void>`
  query {
    page: contact_us_page {
      title
      summary
      phone
      email
      address
      headerImage: header_image {
        id
        filename: filename_download
        title
      }
    }
  }
`;
