import { Component, Input, OnInit } from "@angular/core";
import { LandingPage } from "src/app/interfaces/landing-page";

@Component({
  selector: "app-landing-about-us",
  templateUrl: "./landing-about-us.component.html",
  styleUrls: ["./landing-about-us.component.scss"],
})
export class LandingAboutUsComponent implements OnInit {
  @Input() landingHero!: LandingPage;
  constructor() {}

  ngOnInit(): void {}
}
