import {
  Component,
  OnDestroy,
  OnInit,
  ElementRef,
  ViewChild,
  HostListener,
} from "@angular/core";
import { ReplaySubject, takeUntil } from "rxjs";
import { ContactUsPage } from "src/app/interfaces/contact-us-page";
import { CMSService } from "src/app/services/cms.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-footer-links",
  templateUrl: "./footer-links.component.html",
  styleUrls: ["./footer-links.component.scss"],
})
export class FooterLinksComponent implements OnInit, OnDestroy {
  SCROLL_OFFSET = 300;

  contact!: ContactUsPage;
  portalURI = environment.portalURI;
  @ViewChild("scrollTopBtn") scrollTopButtonRef!: ElementRef<HTMLButtonElement>;

  unsubscribe$ = new ReplaySubject(1);
  footerLinks = [
    {
      title: "المدارس الصديقة",
      href: "/schools",
    },
    {
      title: "من نحن",
      href: "/about-us",
    },
    {
      title: "المبادرة",
      href: "/initiative",
    },
    {
      title: "اخبار وفعاليات",
      href: "/news",
    },
    {
      title: "تواصل معنا",
      href: `mailto: schools@abegs.org`,
      external: true,
    },
    {
      title: "انضم الينا",
      href: "/schools-apply",
    },
  ];

  socialMediaLinks = [
    {
      title: "youtube",
      iconClass: "fa-brands fa-youtube",
      href: "https://www.youtube.com/@AbegsOrg",
    },
    {
      title: "instagram",
      iconClass: "fa-brands fa-instagram",
      href: "https://www.instagram.com/_abegsorg",
    },
    {
      title: "facebook",
      iconClass: "fa-brands fa-facebook",
      href: "https://www.facebook.com/abegsorg/",
    },
    {
      title: "twitter",
      iconClass: "fa-brands fa-twitter",
      href: "https://twitter.com/abegsorg",
    },
  ];
  constructor(private cmsService: CMSService) {}

  ngOnInit() {
    this.fetchContactUsDetails();
  }

  @HostListener("window:scroll")
  onWindowScroll() {
    if (!this.scrollTopButtonRef) return;

    if (window.scrollY > this.SCROLL_OFFSET) {
      this.scrollTopButtonRef.nativeElement.classList.add("shown");
    } else {
      this.scrollTopButtonRef.nativeElement.classList.remove("shown");
    }
  }

  fetchContactUsDetails() {
    this.cmsService
      .getContactUsDetails()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.contact = result;
        },
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }
}
