import { Component, HostListener, OnInit } from "@angular/core";
import { Modal } from "bootstrap";
import { ReplaySubject, takeUntil } from "rxjs";
import { CountrySchools, ModalService } from "src/app/services/modal.service";

@Component({
  selector: "app-schools-modal",
  templateUrl: "./schools-modal.component.html",
  styleUrls: ["./schools-modal.component.scss"],
})
export class SchoolsModalComponent implements OnInit {
  isModalOpen: boolean = false;
  schoolModal: any;

  unsubscrib$ = new ReplaySubject(1);

  constructor(private modalService: ModalService) {}
  countrySchools!: CountrySchools;
  ngOnInit() {
    this.modalService
      .isModalOpen()
      .pipe(takeUntil(this.unsubscrib$))
      .subscribe({
        next: (state) => {
          this.isModalOpen = state;
          this.schoolModal = new Modal("#schoolModal");
          if (this.isModalOpen) {
            this.schoolModal.show();
          } else {
            this.schoolModal.hide();
          }
        },
      });

    this.modalService.getCountrySchools().subscribe({
      next: (countrySchools: CountrySchools) => {
        this.countrySchools = countrySchools;
      },
    });
  }

  ESCAPE_KEYCODE = 27;

  @HostListener("document:keydown", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (event.keyCode === this.ESCAPE_KEYCODE) {
      this.closeModal();
    }
  }

  closeModal() {
    this.isModalOpen = false;
    this.schoolModal.hide();
    this.modalService.setModalState(false);
  }

  ngOnDestroy(): void {
    this.closeModal();
    this.unsubscrib$.next(1);
    this.unsubscrib$.complete();
  }
}
