<section id="news">
  <div class="container">
    <h2 class="mb-4">
      <span
        class="text-gradient subtitle h2 fw-bold"
        style="--gc-1: #00a651; --gc-2: #c29128"
      >
        أخبار وفعاليات
      </span>
    </h2>

    <div flickity [flickityConfig]="flickityConfig" class="slider">
      <div (click)="goToNewsDetails(singleNews)" *ngFor="let singleNews of news" class="single-news cursor-pointer">
        <div class="img-wrapper">
          <img
            [src]="singleNews.image | imageLink"
            class="img-fluid rounded-2 cover d-block mb-3 news-image hover-shadow w-100"
            style="height: 350px"
            [alt]="singleNews.image.title"
            routerLink="/news{{ singleNews.id }}"
          />
        </div>
        <p class="small text-light-gray">{{ singleNews.date | date }}</p>

        <p class="fw-bold text-dark text-center">{{ singleNews.title }}</p>
      </div>
    </div>

    <div class="text-center mt-4">
      <a
        routerLink="/news"
        class="btn btn-light bg-white border-gray rounded-0"
      >
        للمزيد من الأخبار
        <i class="fa-solid fa-angles-left ms-2"></i>
      </a>
    </div>
  </div>
</section>
