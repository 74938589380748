import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  FriendsSchoolsPage,
  FriendsSchoolsPageData,
} from "src/app/interfaces/friends-schools-page";
import {
  LandingCountries,
  LandingSchool,
} from "src/app/interfaces/landing-page";
import { CMSService } from "src/app/services/cms.service";
import { CountrySchools, ModalService } from "src/app/services/modal.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-friends-schools-page",
  templateUrl: "./friends-schools-page.component.html",
  styleUrls: ["./friends-schools-page.component.scss"],
})
export class FriendsSchoolsPageComponent implements OnInit {
  countries!: LandingCountries[];
  friendsSchoolsPage!: FriendsSchoolsPage;
  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    private cmsService: CMSService,
    private http: HttpClient
  ) {}
  ngOnInit() {
    this.getCountries();
    const page = this.route.snapshot.data["page"] as FriendsSchoolsPageData;
    this.friendsSchoolsPage = page.meta;
  }

  openModal(country: any) {    
    this.cmsService.getCountrySchoolsById(country.id).subscribe({
      next: (schools: LandingSchool[]) => {
        let countrySchools: CountrySchools = {
          countryName: country.nameAr,
          logo: country.logo,
          schools: schools,
        };
        this.modalService.setCountrySchools(countrySchools); //get schools by countryid
        this.modalService.setModalState(true);
      },
    });
  }

  getCountries(): void {
    try {
      this.http
        .get<any[]>(`${environment.appServerURI}/countries`)
        .subscribe((data: any) => {
          this.countries = data.data;
        });
    } catch (error) {}
  }
}
