import { gql } from "apollo-angular";

import { SchoolApplyPage, SchoolType } from "../interfaces/school-apply-page";

export const GET_SCHOOL_APPLY_PAGE = gql<
  {
    schoolTypes: SchoolType[];
    page: SchoolApplyPage;
  },
  void
>`
  query {
    page: school_apply_page {
      title
      image: header_image {
        id
        title
        filename: filename_download
      }
      membershipLetter: membership_letter {
        id
        filename_download
        title
      }
    }
  }
`;