import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Apollo } from "apollo-angular";
import { Observable, map } from "rxjs";
import { LandingPageData } from "../interfaces/landing-page";
import { GET_LANDING_PAGE_DATA } from "../queries/landing-page.query";

@Injectable({
  providedIn: "root",
})
export class LandingPageResolver implements Resolve<LandingPageData> {
  constructor(private apollo: Apollo) {}

  resolve(): Observable<LandingPageData> {
    return this.apollo.query({ query: GET_LANDING_PAGE_DATA }).pipe(
      map((result): LandingPageData => {
        const { page: meta, ...page } = result.data;
        return {
          title: "الصفحة الرئيسة",
          data: page,
          meta,
        };
      })
    );
  }
}
