<app-page>
  <page-header></page-header>
  <page-content>
    <div id="who-we-are" class="my-5">
      <div class="container">
        <div class="row mb-5">
          <div class="col-lg-4 col-md-5 mb-3">
            <img
              [src]="aboutUsData.pageImage | imageLink"
              class="img-fluid rounded-4 cover d-block"
              style="aspect-ratio: 1"
              [alt]="aboutUsData.pageImage.title"
            />
          </div>
          <div class="col">
            <p class="small fw-bold text-primary mt-4">
              {{ aboutUsData.title }}
            </p>
            <p class="h6 fw-bold mb-4">
              {{ aboutUsData.summary }}
            </p>

            <p [innerHTML]="aboutUsData.content"></p>
          </div>
        </div>
        <p class="h6 fw-bold d-flex align-items-center mt-5">
          <img src="assets/images/targets.svg" class="me-3" alt="" />
          <span
            class="fs-6 mt-3 pb-2 d-inline-block border-bottom border-3 border-primary"
          >
            أهدافنا
          </span>
        </p>

        <div [innerHTML]="aboutUsData.goals" class="mt-4 ms-4"></div>

        <p class="h6 fw-bold d-flex align-items-center mt-5">
          <img src="assets/images/vision.svg" class="me-3" alt="" />
          <span
            class="fs-6 mt-3 pb-2 d-inline-block border-bottom border-3 border-primary"
          >
            رؤيتنا
          </span>
        </p>

        <div [innerHTML]="aboutUsData.vision" class="mt-4 ms-4"></div>

        <p class="h6 fw-bold d-flex align-items-center mt-5">
          <img src="assets/images/message.svg" class="me-3" alt="" />
          <span
            class="fs-6 mt-3 pb-2 d-inline-block border-bottom border-3 border-primary"
          >
            رسالتنا
          </span>
        </p>

        <div [innerHTML]="aboutUsData.mission" class="mt-4 ms-4"></div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
