import { gql } from "apollo-angular";
import { LandingNews } from "../interfaces/landing-page";
import { NewsPage } from "../interfaces/news-page";
import { SingleNewsPage } from "../interfaces/single-news-page";

export const GET_NEWS_PAGE = gql<
  {
    page: NewsPage;
    news: LandingNews[];
    pagingNews: number;
  },
  { newsLimit: number }
>`
  query ($newsLimit: Int!) {
    page: news_page {
      id
      title
      image: header_image {
        id
        title
        filename: filename_download
      }
    }

    news(
      limit: $newsLimit
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
    ) {
      id
      title
      date
      content
      image {
        id
        filename: filename_download
        title
      }
    }

    pagingNews: news_aggregated(
      filter: { _and: [{ status: { _eq: "published" } }] }
    ) {
      count {
        id
      }
    }

  }
`;

export const LIST_NEWS_BY_PAGE = gql<
  {
    news: LandingNews[];
    pagingNews: number;
  },
  { newsLimit: number; page: number }
>`
  query ($newsLimit: Int!, $page: Int!) {
    news(
      limit: $newsLimit
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
      page: $page
    ) {
      id
      title
      date
      content
      image {
        id
        filename: filename_download
        title
      }
    }
    pagingNews: news_aggregated(
      filter: { _and: [{ status: { _eq: "published" } }] }
    ) {
      count {
        id
      }
    }

  }
`;

export const GET_SINGLE_NEWS_PAGE = gql<
  {
    page: SingleNewsPage;
    singleNews: LandingNews;
  },
  { singleNewsId: number }
>`
  query ($singleNewsId: ID!) {
    page: news_page {
      title
      image: header_image {
        id
        filename: filename_download
        title
      }
    }
    singleNews: news_by_id(id: $singleNewsId) {
      id
      title
      date
      content
      image {
        id
        filename: filename_download
        title
      }
    }
  }
`;
