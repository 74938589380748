import { gql } from "apollo-angular";
import { InitiativePage } from "../interfaces/initiative-page";

export const GET_INITIATIVE_PAGE = gql<{ page: InitiativePage }, void>`
  query {
    page: initiative_page {
      title
      image: header_image {
        id
        filename: filename_download
        title
      }
    }
  }
`;
