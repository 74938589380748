<div class="calendar-container">
  <mat-calendar
    #calendar
    [startView]="startView"
    [dir]="direction"
    (selectedChange)="onSelectedChange($event)"
    (monthSelected)="onMonthSelected($event)"
    (yearSelected)="onYearSelected($event)"
    (viewChanged)="viewChangedHandler()"
    style="font-size: 20px"
  ></mat-calendar>
</div>
