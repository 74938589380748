<section *ngIf="countries" id="friends-schools">
  <div class="container">
    <h2 class="mb-4">
      <span
        class="text-gradient subtitle h2 fw-bold"
        style="--gc-1: #00a651; --gc-2: #c29128"
      >
        المدارس الصديقة للمكتب
      </span>
    </h2>

    <div class="slider">
      <div
        *ngFor="let country of countries"
        (click)="openModal(country)"
        class="single-country cursor-pointer"
      >
        <div class="img-wrapper">
          <img
            [src]="country.image"
            class="rounded-2 cover d-block mb-3 country-image hover-shadow"
            style="width: 100%; aspect-ratio: 1"
            [alt]="country.nameAr"
          />
          <div class="flag-wrapper">
            <img src="assets/images/flag-wrapper.svg" class="mask" alt="" />
            <img [src]="country.logo" class="flag" [alt]="country.nameAr" />
          </div>
        </div>
        <p class="fw-bold small text-center mt-4">{{ country.nameAr }}</p>
      </div>
    </div>
  </div>
</section>
