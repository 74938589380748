<app-page>
  <page-header></page-header>
  <page-content>
    <div id="single-news" class="my-5">
      <div class="container">
        <div id="news" class="my-5">
          <div class="container">
            <div class="row mb-5">
              <div class="col-lg-3 col-md-4 mb-3">
                <img
                  [src]="singleNews.image | imageLink"
                  class="img-fluid rounded-2 cover d-block news-image"
                  style="aspect-ratio: 1"
                  [alt]="singleNews.image.title"
                />
              </div>
              <div class="col">
                <p class="small text-light-gray">
                  {{ singleNews.date | date }}
                </p>
                <p class="h6 fw-bold">{{ singleNews.title }}</p>

                <p [innerHTML]="singleNews.content" class="mt-4 ms-2 ps-4"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
