import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ReplaySubject, takeUntil } from "rxjs";
import { Direction } from "src/app/enums/direction";
import { Initiative } from "src/app/interfaces/initiative-page";
import { LocalizationService } from "src/app/services/localization.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-landing-latest-initiative",
  templateUrl: "./landing-latest-initiative.component.html",
  styleUrls: ["./landing-latest-initiative.component.scss"],
})
export class LandingLatestInitiativeComponent implements OnInit {
  flickityConfig: Flickity.Options = {
    rightToLeft: true,
    cellAlign: "right",
    wrapAround: true,
    contain: true,
    pageDots: true,
    prevNextButtons: false,
  };
  latestInitiative!: Initiative;

  unsubscribe$ = new ReplaySubject();

  constructor(
    private localizationService: LocalizationService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.getInitiatives();
    this.localizationService
      .getLocale()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((locale) => {
        this.flickityConfig.rightToLeft =
          locale.direction === Direction.RIGHT_TO_LEFT;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  getInitiatives(): void {
    try {
      this.http
        .get<Initiative>(`${environment.appServerURI}/initiatives`)
        .subscribe((data: Initiative) => {
          this.latestInitiative = data;
        });
    } catch (error) {}
  }
}
