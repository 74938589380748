import { Component, Input, OnInit } from "@angular/core";
import {
  LandingPage,
  LandingSubscriptions,
} from "src/app/interfaces/landing-page";

@Component({
  selector: "app-landing-schools-membership",
  templateUrl: "./landing-schools-membership.component.html",
  styleUrls: ["./landing-schools-membership.component.scss"],
})
export class LandingSchoolsMembershipComponent implements OnInit {
  @Input() landingHero!: LandingPage;
  @Input() subscriptions!: LandingSubscriptions[];
  constructor() {}

  ngOnInit(): void {}

  // benefits = [
  //   "50% خصم على معظم إصدارات المكتب",
  //   "50% خصم على حضور الدورات التدريبية",
  //   "المشاركة في المسابقات الخاصة بالمدارس الصديقة",
  //   "اشتراك مجاني بمجلة رسالة الخليج",
  // ];
}
