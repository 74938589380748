import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, map } from "rxjs";
import { Apollo } from "apollo-angular";
import { GET_INITIATIVE_PAGE } from "../queries/initiative-page.query";
import { InitiativePageData } from "../interfaces/initiative-page";

@Injectable({
  providedIn: "root",
})
export class InitiativePageResolver implements Resolve<InitiativePageData> {
  constructor(private apollo: Apollo) {}

  resolve(): Observable<InitiativePageData> {
    return this.apollo
      .query({
        query: GET_INITIATIVE_PAGE,
      })
      .pipe(
        map((result): InitiativePageData => {
          const { page: meta, ...page } = result.data;
          return {
            title: "االمبادرة",
            hero: {
              title: meta.title,
              image: meta.image,
            },
            data: page,
            meta,
          };
        })
      );
  }
}
