import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { LandingSchool } from "../interfaces/landing-page";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  private _openModal = new BehaviorSubject<boolean>(false);
  private _countrySchools = new BehaviorSubject<CountrySchools>({
    countryName: "",
    logo: "",
    schools: [],
  });

  isModalOpen() {
    return this._openModal.asObservable();
  }

  getCountrySchools() {
    return this._countrySchools.asObservable();
  }
  setModalState(isOpen: boolean) {
    return this._openModal.next(isOpen);
  }

  setCountrySchools(countrySchools: CountrySchools) {
    return this._countrySchools.next(countrySchools);
  }
}

export interface CountrySchools {
  countryName: string;
  logo: string;
  schools: LandingSchool[];
}