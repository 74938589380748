import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import {
  SchoolApplyPage,
  SchoolApplyPageData,
} from "src/app/interfaces/school-apply-page";
import { environment } from "src/environments/environment";
import { ReplaySubject } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-schools-apply-page",
  templateUrl: "./schools-apply-page.component.html",
  styleUrls: ["./schools-apply-page.component.scss"],
})
export class SchoolsApplyPageComponent implements OnInit, OnDestroy {
  constructor(private route: ActivatedRoute, private http: HttpClient) {}
  schoolApplyPage!: SchoolApplyPage;
  schoolsTypes: SchoolsType[] = [];
  countries: Country[] = [];
  unsubscribe$ = new ReplaySubject(1);

  formGroup = new FormGroup({
    name: new FormControl("", {
      nonNullable: true,
      validators: [Validators.required],
    }),

    phone: new FormControl(""),

    mobile: new FormControl(""),

    website: new FormControl(""),

    email: new FormControl("", {
      nonNullable: true,
      validators: [Validators.required],
    }),

    capacity: new FormControl(""),

    type: new FormControl(null, {
      nonNullable: true,
      validators: [Validators.required],
    }),

    manager: new FormControl("", {
      nonNullable: true,
      validators: [Validators.required],
    }),

    branches: new FormControl("", {
      nonNullable: true,
      validators: [Validators.required],
    }),

    country: new FormControl(null, {
      nonNullable: true,
      validators: [Validators.required],
    }),

    city: new FormControl("", {
      nonNullable: true,
      validators: [Validators.required],
    }),

    postOfficeBox: new FormControl("", {
      nonNullable: true,
      validators: [Validators.required],
    }),

    membershipLetter: new FormControl("", {
      nonNullable: true,
      validators: [Validators.required],
    }),

    notesAchnowledgment: new FormControl("", {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  sending!: boolean;
  success!: boolean;
  hasError!: boolean;

  get name() {
    return this.formGroup.controls["name"];
  }

  get phone() {
    return this.formGroup.controls["phone"];
  }

  get mobile() {
    return this.formGroup.controls["mobile"];
  }

  get website() {
    return this.formGroup.controls["website"];
  }

  get email() {
    return this.formGroup.controls["email"];
  }

  get capacity() {
    return this.formGroup.controls["capacity"];
  }

  get type() {
    return this.formGroup.controls["type"];
  }

  get manager() {
    return this.formGroup.controls["manager"];
  }

  get branches() {
    return this.formGroup.controls["branches"];
  }

  get country() {
    return this.formGroup.controls["country"];
  }

  get city() {
    return this.formGroup.controls["city"];
  }

  get postOfficeBox() {
    return this.formGroup.controls["postOfficeBox"];
  }

  get membershipLetter() {
    return this.formGroup.controls["membershipLetter"];
  }

  get notesAchnowledgment() {
    return this.formGroup.controls["notesAchnowledgment"];
  }

  ngOnInit() {
    const page = this.route.snapshot.data["page"] as SchoolApplyPageData;
    this.schoolApplyPage = page.meta;
    this.getCountries();
    this.getSchoolsTypes();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }
  onSubmit() {
    if (this.formGroup && this.formGroup.invalid) {
      return;
    }

    const schoolData = this.formGroup.value;
    const membershipLetterFileInput: HTMLInputElement = document.getElementById(
      "file-1"
    ) as HTMLInputElement;
    const notesAcknowledgmentFileInput: HTMLInputElement =
      document.getElementById("file-2") as HTMLInputElement;
    const membershipLetterFile =
      membershipLetterFileInput.files && membershipLetterFileInput.files[0];
    const notesAcknowledgmentFile =
      notesAcknowledgmentFileInput.files &&
      notesAcknowledgmentFileInput.files[0];

    const formData = new FormData();
    formData.append("Name", schoolData.name!);
    formData.append("Phone", schoolData.phone!);
    formData.append("Mobile", schoolData.mobile!);
    formData.append("Website", schoolData.website!);
    formData.append("Email", schoolData.email!);
    formData.append("Capacity", schoolData.capacity!);
    formData.append("TypeId", schoolData.type!);
    formData.append("Manager", schoolData.manager!);
    formData.append("Branches", schoolData.branches!);
    formData.append("CountryId", schoolData.country!);
    formData.append("City", schoolData.city!);
    formData.append("PostOfficeBox", schoolData.postOfficeBox!);
    formData.append("MembershipLetter", membershipLetterFile!);
    formData.append("NotesAcknowledgment", notesAcknowledgmentFile!);
    this.createSchoolSubscription(formData);
  }

  createSchoolSubscription(formData: FormData) {
    this.sending = true;
    this.http
      .post(`${environment.appServerURI}/schools`, formData)
      .subscribe(
        (response) => {
          this.sending = false;
          this.success = true;
          this.formGroup.reset();
        },
        (error) => {
          console.error("Error:", error);
          this.sending = this.success = false;
          this.hasError = true;
        }
      );
  }
  getSchoolsTypes(): void {
    try {
      this.http
        .get<any[]>(`${environment.appServerURI}/schools/types`)
        .subscribe((data: any) => {
          this.schoolsTypes = data.data;
        });
    } catch (error) {}
  }

  getCountries(): void {
    try {
      this.http
        .get<any[]>(`${environment.appServerURI}/countries`)
        .subscribe((data: any) => {
          this.countries = data.data;
        });
    } catch (error) {}
  }

}

export interface SchoolSubscription {
  Name: string;
  Phone: string;
  Mobile: string;
  Website: string;
  Email: string;
  Capacity: number;
  TypeId: number;
  Manager: string;
  Branches: number;
  CountryId: number;
  City: string;
  PostOfficeBox: string;
  MembershipLetter: any;
  NotesAcknowledgment: any;
}

export interface SchoolsType {
  id: string;
  nameAr: string;
  nameEn: string;
}

export interface Country {
  id: string;
  nameAr: string;
  nameEn: string;
  nationalityAr: string;
  nationalityEn: string;
  code: string;
}