import { gql } from "apollo-angular";
import { AboutUsPage } from "../interfaces/about-us-page";

export const GET_ABOUT_US_PAGE = gql<{ page: AboutUsPage }, void>`
  query {
    page: about_page {
      title
      summary
      content
      vision
      mission
      goals
      pageImage: image {
        id
        filename: filename_download
        title
      }
      image: header_image {
        id
        filename: filename_download
        title
      }
    }
  }
`;
