<div id="home-hero">
  <div>
    <img src="assets/images/intro-bg.webp" class="intro-image-schools" alt="" />
  </div>

  <!-- <div class="container position-relative h-100 pt-7">
    <div class="row h-100 pb-5">
      <div class="col-lg-7 col-md-6 mb-5 mb-md-0 ">
        <div class="title h6 fw-bold d-inline-block">
          <span
            class="text-gradient"
            style="--gc-1: #00a651; --gc-2: #c29128"
            >{{ landingHero.title }}</span
          >
        </div>
        <div
          [innerHTML]="landingHero.summary"
          class="subtitle h4 fw-bold"
          style="line-height: inherit"
        ></div>
      </div>
      <div class="col px-5 px-lg-3">
        <div class="hero-image">
          <img src="assets/images/hero-pattern.svg" class="pattern" alt="" />

          <div class="row gap-2">
            <div class="col-12">
              <div
                class="img-wrapper rounded-5 overflow-hidden"
                style="height: 190px"
              >
                <img
                  [src]="landingHero.homeImage1 | imageLink"
                  class="cover h-100 w-100"
                  [alt]="landingHero.homeImage1.title"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="row gap-2">
                <div class="col-8 pe-0">
                  <div
                    class="img-wrapper rounded-5 overflow-hidden"
                    style="height: 230px"
                  >
                    <img
                      [src]="landingHero.homeImage2 | imageLink"
                      class="cover h-100 w-100"
                      [alt]="landingHero.homeImage2.title"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="row gap-2">
                    <div class="col-12 ps-0">
                      <div
                        class="img-wrapper rounded-5 overflow-hidden"
                        style="height: 110px"
                      >
                        <img
                          [src]="landingHero.homeImage3 | imageLink"
                          class="cover h-100 w-100"
                          [alt]="landingHero.homeImage3.title"
                        />
                      </div>
                    </div>
                    <div class="col-12 ps-0">
                      <div
                        class="img-wrapper rounded-5 overflow-hidden"
                        style="height: 110px"
                      >
                        <img
                          [src]="landingHero.homeImage4 | imageLink"
                          class="cover h-100 w-100"
                          [alt]="landingHero.homeImage4.title"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</div>

<div class="my-3 pt-3 position-relative">
  <div id="prize-about"></div>
  <div class="container">
    <div class="text-center">
      <h2
        class="fw-bold mt-4 mb-5 text-gradient subtitle h2"
        style="--gc-1: #00a651; --gc-2: #c29128"
      >
        برنامج المدارس الصديقة
      </h2>
    </div>

    <div
      class="d-flex flex-column justify-content-center align-items-center gap-5"
    >
      <ul
        class="nav nav-pills mx-auto justify-content-center align-items-center"
        role="tablist"
      >
        <li
          *ngFor="let schoolsAboutElement of schoolsAboutArray; index as i"
          class="nav-item mx-2"
          role="presentation"
        >
          <button
            class="nav-link px-4"
            [ngClass]="{ active: i === 0 }"
            id="tab{{ i }}-tab"
            data-bs-toggle="tab"
            attr.data-bs-target="#tab{{ i }}-tab-pane"
            type="button"
            role="tab"
            attr.aria-controls="tab{{ i }}-tab-pane"
            aria-selected="false"
          >
            {{ schoolsAboutElement.title }}
          </button>
        </li>
      </ul>

      <div class="tab-content card" style="max-width: 90%; min-width: 90%">
        <div
          *ngFor="let schoolsAboutElement of schoolsAboutArray; index as i"
          class="tab-pane fade show card-body p-5"
          [ngClass]="{ active: i === 0 }"
          id="tab{{ i }}-tab-pane"
          attr.role="tabpane{{ i }}"
          attr.aria-labelledby="tab{{ i }}-tab"
          tabindex="0"
        >
          <div class="">
            <p
              [innerHTML]="schoolsAboutElement.content"
              class="regulation-content"
            ></p>
          </div>
        </div>
        <div
          *ngFor="let schoolsAboutElement of schoolsAboutArray; index as i"
          class="tab-pane fade"
          id="tab{{ i + 1 }}-tab-pane"
          role="tabpanel"
          attr.aria-labelledby="tab{{ i + 1 }}-tab"
          tabindex="0"
        >
          ...
        </div>
      </div>
    </div>
  </div>
</div>
