<app-page>
  <page-header></page-header>
  <page-content>
    <div
      *ngIf="!latestInitiative"
      class="no-data text-center mt-5"
      style="min-height: 300px"
    >
      <p class="alert alert-info w-50 text-center mx-auto">
        لا توجد مبادرات حاليه
      </p>
    </div>
    <div *ngIf="latestInitiative" id="mubadara-page" class="my-5">
      <div class="container">
        <div class="row mb-5">
          <div class="col-lg-4 col-md-5 mb-3">
            <img
              [src]="latestInitiative.image.path"
              class="img-fluid rounded-4 cover d-block"
              style="aspect-ratio: 1"
              [alt]="latestInitiative.image.name"
            />
          </div>
          <div class="col">
            <p class="small text-primary fw-bold">
              {{ latestInitiative.startDate }} -
              {{ latestInitiative.endDate }}
            </p>
            <p class="h6 fw-bold mb-4">
              {{ latestInitiative.name }}
            </p>

            <p>
              {{ latestInitiative.description }}
            </p>
          </div>
        </div>

        <div
          *ngFor="let competition of latestInitiative.competitions; index as i"
        >
          <div class="alert alert-success rounded-pill border-0 fw-bold mb-3">
            <span
              class="text-gradient"
              style="--gc-1: #00a651; --gc-2: #c29128"
            >
              <span *ngFor="let arrangeWord of arrangeWords">
                <span *ngIf="arrangeWord.index === i"
                  >{{ arrangeWord.word }} :
                </span>
              </span>
              {{ competition.name }}:
              {{ competition.description }}
            </span>
          </div>

          <p
            class="h6 fw-bold d-inline-block border-bottom border-1 border-primary pb-3 px-3"
          >
            شروط المسابقة:
          </p>
          <div [innerHTML]="competition.conditions" class="ms-4 mb-5"></div>
        </div>

        <div
          [innerHTML]="latestInitiative.instructions"
          class="alert alert-success rounded-5 border-0 mb-3 mt-5 px-5 py-4"
        ></div>
      </div>
      <hr *ngIf="latestInitiative.showResult" class="mt-5" />
      <div *ngIf="latestInitiative.showResult" class="text-center mt-5">
        <a
          routerLink="/initiative-result/1"
          class="mx-auto small btn btn-primary px-3"
          routerLink="/initiative-result"
          >نتيجة المبادرة</a
        >
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
