import { Component, Input, OnInit } from "@angular/core";
import { LandingPage } from "src/app/interfaces/landing-page";

@Component({
  selector: "app-landing-hero",
  templateUrl: "./landing-hero.component.html",
  styleUrls: ["./landing-hero.component.scss"],
})
export class LandingHeroComponent implements OnInit {
  @Input() landingHero!: LandingPage;

  schoolsAboutArray!: { title: string; content: string }[];

  ngOnInit() {
    this.schoolsAboutArray = [
      { title: "هدف البرنامج", content: this.landingHero.summary },
      {
        title: this.landingHero.joiningMechanismTitle,
        content: this.landingHero.joiningMechanismDescription,
      },
    ];
  }
}
