import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, map } from "rxjs";
import { Apollo } from "apollo-angular";
import { SchoolApplyPageData } from "../interfaces/school-apply-page";
import { GET_SCHOOL_APPLY_PAGE } from "../queries/school-apply-page.query";

@Injectable({
  providedIn: "root",
})
export class SchoolApplyPageResolver implements Resolve<SchoolApplyPageData> {
  constructor(private apollo: Apollo) {}

  resolve(): Observable<SchoolApplyPageData> {
    return this.apollo
      .query({
        query: GET_SCHOOL_APPLY_PAGE,
      })
      .pipe(
        map((result): SchoolApplyPageData => {
          const { page: meta, ...page } = result.data;
          return {
            title: "انضم الينا",
            hero: {
              title: meta.title,
              image: meta.image,
            },
            data: page,
            meta,
          };
        })
      );
  }
}
