import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Initiative } from "src/app/interfaces/initiative-page";
import {
  InitiativeResultPage,
  InitiativeResultPageData,
} from "src/app/interfaces/initiative-result-page";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-initiative-result-page",
  templateUrl: "./initiative-result-page.component.html",
  styleUrls: ["./initiative-result-page.component.scss"],
})
export class InitiativeResultPageComponent implements OnInit {
  initiativeResultPage!: InitiativeResultPage;
  totalApplicationsNumber!: number;
  passedApplicationsNumber!: number;
  totalAppliedSchoolsNumber!: number;

  latestInitiative!: any;
  constructor(private route: ActivatedRoute, private http: HttpClient) {}
  schoolsApps: any;
  studentsApps: any;
  teachersApps: any;

  ngOnInit() {
    this.getInitiatives();

    const page = this.route.snapshot.data["page"] as InitiativeResultPageData;
    this.initiativeResultPage = page.meta;
  }

  getInitiatives(): void {
    try {
      this.http
        .get<Initiative>(`${environment.appServerURI}/initiatives`)
        .subscribe((data: Initiative) => {
          console.log(data, "data");

          this.latestInitiative = data;
          if (data) {
            this.totalApplicationsNumber = this.latestInitiative.applications;
            this.totalAppliedSchoolsNumber = this.latestInitiative.schools;
            this.passedApplicationsNumber =
              this.latestInitiative.results.length;

            this.schoolsApps = this.latestInitiative.results.filter(
              (app) => app.code === "1"
            );
            this.teachersApps = this.latestInitiative.results.filter(
              (app) => app.code === "2"
            );
            this.studentsApps = this.latestInitiative.results.filter(
              (app) => app.code === "3"
            );
          }
        });
    } catch (error) {}
  }
}
