<app-page>
  <page-header></page-header>
  <page-content>
    <div id="apply" class="my-5">
      <div class="container">
        <form class="row" [formGroup]="formGroup">
          <div class="mb-3 col-md-6">
            <label class="mb-1" for="name"> اسم المدرسة: </label>
            <input
              required
              type="text"
              class="form-control focus-shadow-none"
              [ngClass]="{
                'is-invalid': name.invalid && (name.dirty || name.touched)
              }"
              id="name"
              formControlName="name"
            />
            <div *ngIf="name.hasError('required')" class="invalid-feedback">
              اسم المدرسة مطلوب
            </div>
          </div>

          <div class="mb-3 col-md-6">
            <label class="mb-1" for="phone"> الهاتف </label>
            <input
              type="text"
              class="form-control focus-shadow-none"
              [ngClass]="{
                'is-invalid': phone.invalid && (phone.dirty || phone.touched)
              }"
              id="phone"
              formControlName="phone"
            />
            <div *ngIf="phone.hasError('required')" class="invalid-feedback">
              الهاتف مطلوب
            </div>
          </div>

          <div class="mb-3 col-md-6">
            <label class="mb-1" for="mobile"> رقم الجوال </label>
            <input
              type="text"
              class="form-control focus-shadow-none"
              [ngClass]="{
                'is-invalid': mobile.invalid && (mobile.dirty || mobile.touched)
              }"
              id="mobile"
              formControlName="mobile"
            />
            <div *ngIf="mobile.hasError('required')" class="invalid-feedback">
              رقم الجوال مطلوب
            </div>
          </div>

          <div class="mb-3 col-md-6">
            <label class="mb-1" for="website"> الموقع الالكتروني </label>
            <input
              type="text"
              class="form-control focus-shadow-none"
              id="website"
              formControlName="website"
            />
          </div>

          <div class="mb-3 col-md-6">
            <label class="mb-1" for="email"> البريد الالكتروني </label>
            <input
              required
              type="text"
              class="form-control focus-shadow-none"
              [ngClass]="{
                'is-invalid': email.invalid && (email.dirty || email.touched)
              }"
              id="email"
              formControlName="email"
            />
            <div *ngIf="email.hasError('required')" class="invalid-feedback">
              الايميل مطلوب
            </div>
          </div>

          <div class="mb-3 col-md-6">
            <label class="mb-1" for="capacity">
              العدد الكلي لمنسوبي المدرسة
            </label>
            <input
              type="text"
              class="form-control focus-shadow-none"
              id="capacity"
              formControlName="capacity"
            />
          </div>

          <div class="mb-3 col-md-6">
            <label class="mb-1" for="type"> نوع المدرسة: </label>
            <select
              required
              type="text"
              class="form-control focus-shadow-none form-select"
              [ngClass]="{
                'is-invalid': type.invalid && (type.dirty || type.touched)
              }"
              id="type"
              formControlName="type"
            >
              <option *ngFor="let type of schoolsTypes" [value]="type.id">
                {{ type.nameAr }}
              </option>
            </select>
            <div *ngIf="type.hasError('required')" class="invalid-feedback">
              نوع المدرسة مطلوب
            </div>
          </div>

          <div class="mb-3 col-md-6">
            <label class="mb-1" for="manager"> اسم مدير المدرسة: </label>
            <input
              required
              type="text"
              class="form-control focus-shadow-none"
              [ngClass]="{
                'is-invalid':
                  manager.invalid && (manager.dirty || manager.touched)
              }"
              id="manager"
              formControlName="manager"
            />
            <div *ngIf="manager.hasError('required')" class="invalid-feedback">
              اسم مدير المدرسة مطلوب
            </div>
          </div>

          <div class="mb-3 col-md-6">
            <label class="mb-1" for="branches">
              عدد الفروع (شامل الفرع الرئيسي):
            </label>
            <input
              required
              type="text"
              class="form-control focus-shadow-none"
              id="branches"
              formControlName="branches"
              [ngClass]="{
                'is-invalid':
                  branches.invalid && (branches.dirty || branches.touched)
              }"
            />
            <div *ngIf="branches.hasError('required')" class="invalid-feedback">
              عدد الفروع مطلوب
            </div>
          </div>

          <div *ngIf="countries" class="mb-3 col-md-6">
            <label class="mb-1" for="country"> الدولة: </label>
            <select
              required
              country="text"
              class="form-control focus-shadow-none form-select"
              [ngClass]="{
                'is-invalid':
                  country.invalid && (country.dirty || country.touched)
              }"
              id="country"
              formControlName="country"
            >
              <option *ngFor="let country of countries" [value]="country.id">
                {{ country.nameAr }}
              </option>
            </select>
            <div *ngIf="country.hasError('required')" class="invalid-feedback">
              الدولة مطلوب
            </div>
          </div>

          <div class="mb-3 col-md-6">
            <label class="mb-1" for="city">المدينة</label>
            <input
              required
              type="text"
              class="form-control focus-shadow-none"
              [ngClass]="{
                'is-invalid': city.invalid && (city.dirty || city.touched)
              }"
              id="city"
              formControlName="city"
            />
            <div *ngIf="city.hasError('required')" class="invalid-feedback">
              المدينة مطلوبة
            </div>
          </div>

          <div class="mb-3 col-md-6">
            <label class="mb-1" for="postOfficeBox"> رقم صندوق البريد </label>
            <input
              required
              type="text"
              class="form-control focus-shadow-none"
              [ngClass]="{
                'is-invalid':
                  postOfficeBox.invalid &&
                  (postOfficeBox.dirty || postOfficeBox.touched)
              }"
              id="postOfficeBox"
              formControlName="postOfficeBox"
            />
            <div
              *ngIf="postOfficeBox.hasError('required')"
              class="invalid-feedback"
            >
              رقم صندوق البريد مطلوب
            </div>
          </div>

          <div class="mb-3">
            <label class="mb-1" for="file-1">
              خطاب موجهة من ادارة المدرسة لمدير عام المكتب بطلب الانتساب:
              <p class="text-danger small">
                صيغة الخطاب تكون كما يلي

                <a
                  [href]="schoolApplyPage.membershipLetter | fileLink"
                  target="_blank"
                  >تحميل</a
                >
              </p>
            </label>
            <input
              required
              type="file"
              class="form-control focus-shadow-none"
              id="file-1"
              [ngClass]="{
                'is-invalid':
                  membershipLetter.invalid &&
                  (membershipLetter.dirty || membershipLetter.touched)
              }"
              formControlName="membershipLetter"
            />
            <div
              *ngIf="membershipLetter.hasError('required')"
              class="invalid-feedback"
            >
              الخطاب مطلوب
            </div>
          </div>

          <div class="mb-3">
            <label class="mb-1" for="file-2">
              إقرار مختوم من ادارة المدرسة بعدم وجود ملاحظات على المدرسة:
            </label>
            <input
              required
              type="file"
              class="form-control focus-shadow-none"
              id="file-2"
              [ngClass]="{
                'is-invalid':
                  notesAchnowledgment.invalid &&
                  (notesAchnowledgment.dirty || notesAchnowledgment.touched)
              }"
              formControlName="notesAchnowledgment"
            />
            <div
              *ngIf="membershipLetter.hasError('required')"
              class="invalid-feedback"
            >
              الاقرار مطلوب
            </div>
          </div>

          <div class="text-end">
            <button
              class="btn btn-primary px-5"
              (click)="onSubmit()"
              [disabled]="sending || formGroup.invalid || success || hasError"
              [ngClass]="{ 'btn-primary': !hasError, 'btn-danger': hasError }"
            >
              <span *ngIf="!success && !hasError">
                <i
                  *ngIf="sending"
                  class="fa-solid fa-circle-notch fa-spin ms-1"
                ></i>
                تقديم
              </span>
              <span *ngIf="success" class="fade-in-down">
                <i class="fa-solid fa-check"></i>
                تم إرسال النموذج بنجاح
              </span>
              <span *ngIf="hasError" class="fade-in-down">
                <i class="fa-solid fa-triangle-exclamation"></i>
                حدث خطأ يرجي المحاولة لاحقًا
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
