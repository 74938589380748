/* eslint-disable @typescript-eslint/ban-ts-comment */
import {Injectable} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import {first} from "rxjs";
import {environment} from "src/environments/environment";

import {ExtendedWindow, WindowService} from "./window.service";

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  private running = false;
  private window!: ExtendedWindow;

  constructor(private router: Router, private windowService: WindowService) {}

  run() {
    if (this.running) return;

    const window = this.windowService.getWindow();
    if (!window) {
      console.warn("[AnalyticsService] Window is not available.");
      return;
    }

    this.window = window;

    this.injectScript();

    this.running = true;
  }

  private injectScript() {
    this.router.events.pipe(first((event) => event instanceof NavigationEnd)).subscribe(() => {
      const script = this.window.document.createElement("script");
      script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.analytics.measurementId}`;
      script.async = true;

      this.window.document.head.appendChild(script);

      this.gtag("js", new Date());
      this.gtag("config", environment.analytics.measurementId);

      console.debug("[AnalyticsService] Script has been injected.");
    });
  }

  get dataLayer() {
    return (this.window["dataLayer"] ??= []);
  }

  get gtag() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-this-alias
    const self = this;
    return (this.window["gtag"] ??= function () {
      // eslint-disable-next-line prefer-rest-params
      self.dataLayer.push(arguments);
    });
  }
}
