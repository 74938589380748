import { gql } from "apollo-angular";
import { FriendsSchoolsPage } from "../interfaces/friends-schools-page";

export const GET_FRIENDS_SCHOOLS_PAGE = gql<{ page: FriendsSchoolsPage }, void>`
  query {
    page: friends_schools_page {
      id
      title
      image: header_image {
        id
        title
        filename: filename_download
      }
    }
  }
`;
