<section
  *ngIf="latestInitiative"
  id="mubadara"
  class="my-8 position-relative"
  style="background-color: #e8f7ef"
>
  <img src="assets/images/mubadara-top.svg" class="pattern top" alt="" />
  <div class="container">
    <div class="row">
      <div class="col-md-6 d-flex flex-column justify-content-center">
        <p class="fw-bold text-primary">{{ latestInitiative.name }}</p>
        <p class="h4 fw-bold w-75">
          {{ latestInitiative.name }}
        </p>
        <p>
          {{ latestInitiative.summary }}
        </p>

        <div>
          <a
            routerLink="initiative"
            style="border: 1px solid lightgray"
            class="btn btn-light bg-transparent border-1 border-gray rounded-0"
          >
            تفاصيل المبادرة
          </a>
        </div>
      </div>
      <div class="col mt-4 mt-md-0">
        <div flickity [flickityConfig]="flickityConfig" class="slider">
          <img
            [src]="latestInitiative.image.path"
            class="rounded-3 cover w-100 mx-2"
            style="height: 430px"
            [alt]="latestInitiative.image.name"
          />
        </div>
      </div>
    </div>
  </div>
  <img src="assets/images/mubadara-bottom.svg" class="pattern bottom" alt="" />
</section>
