<app-navbar></app-navbar>
<div id="home">
  <app-landing-hero [landingHero]="landingHero"></app-landing-hero>
  <!-- <app-landing-about-us [landingHero]="landingHero"></app-landing-about-us> -->
  <app-landing-friends-schools
    [countries]="countries"
  ></app-landing-friends-schools>
  <app-landing-latest-initiative></app-landing-latest-initiative>
  <app-landing-news-activities [news]="news"></app-landing-news-activities>
  <app-landing-schools-membership
    [landingHero]="landingHero"
    [subscriptions]="subscriptions"
  ></app-landing-schools-membership>
</div>
<app-footer-links></app-footer-links>
<app-copyright></app-copyright>
