<section id="membership" class="bg-primary bg-opacity-10">
  <div class="container">
    <h2 class="mb-4">
      <span
        class="text-gradient subtitle h2 fw-bold"
        style="--gc-1: #00a651; --gc-2: #c29128"
      >
        عضوية المدارس الصديقة
      </span>
    </h2>

    <div class="row">
      <div
        class="col-md-6 d-flex flex-column justify-content-center mb-4 mb-md-5"
      >
        <h6 class="h4 fw-bold mb-4">الشروط والضوابط</h6>
        <div [innerHTML]="landingHero.membershipConditions"></div>
      </div>
      <div class="col-md-6 mb-4 mb-md-5">
        <div
          class="img-gradient-wrapper"
          style="--gc-1: #00a651; --gc-2: #c29128"
        >
          <img
            [src]="landingHero.membershipImage2 | imageLink"
            class="rounded-pill cover w-100"
            [alt]="landingHero.membershipImage2.title"
          />
        </div>
      </div>
      <div class="col-md-6 mb-4 mb-md-5">
        <div
          class="img-gradient-wrapper"
          style="--gc-1: #00a651; --gc-2: #c29128"
        >
          <img
            [src]="landingHero.membershipImage1 | imageLink"
            class="rounded-pill cover w-100"
            [alt]="landingHero.membershipImage1.title"
          />
        </div>
      </div>
      <div
        class="col-md-6 d-flex flex-column justify-content-center mb-4 mb-md-5"
      >
        <h6 class="h4 fw-bold mb-4">ما سيقدمه المكتب للمدارس المنتسبة</h6>

        <div [innerHTML]="landingHero.membershipFeatures"></div>
      </div>
    </div>

    <!-- <h6 class="h5 fw-bold text-center mb-3 mt-3">اشتراك سنوي</h6>
    <h2 class="mb-2">
      <span
        class="text-gradient subtitle h2 fw-bold"
        style="--gc-1: #00a651; --gc-2: #c29128; line-height: 2.6rem"
      >
        تمنح المدارس المشتركة المزايا التالية
      </span>
    </h2> -->

    <div class="row justify-content-center mt-4">
      <div class="col-xl-6 col-lg-6 col-md-6 supscription-col text-center">
        <table class="table text-center fw-bold table-striped table-borderless">
          <!-- <tbody>
            <tr *ngFor="let benefit of benefits">
              <td class="px-1">{{ benefit }}</td>
            </tr>
          </tbody> -->
          <tfoot>
            <tr
              routerLink="/schools-apply"
              class="py-3 text-white bg-primary apply-btn cursor-pointer"
            >
              <td>
                <a class="text-light"> تقدم بطلب الاشتراك </a>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</section>
