import { gql } from "apollo-angular";
import {
  LandingPage,
  LandingAboutUs,
  LandingNews,
} from "../interfaces/landing-page";

export const GET_LANDING_PAGE_DATA = gql<
  {
    page: LandingPage;
    aboutUs: LandingAboutUs;
    news: LandingNews[];
  },
  void
>`
  query {
    page: home_page {
      title
      summary
      homeImage1: home_image1 {
        id
        filename: filename_download
        title
      }
      homeImage2: home_image2 {
        id
        filename: filename_download
        title
      }
      homeImage3: home_image3 {
        id
        filename: filename_download
        title
      }
      homeImage4: home_image4 {
        id
        filename: filename_download
        title
      }
      membershipImage1: membership_image1 {
        id
        filename: filename_download
        title
      }
      membershipImage2: membership_image2 {
        id
        filename: filename_download
        title
      }
      membershipConditions: membership_conditions
      membershipFeatures: membership_features
      joiningMechanismTitle: joining_mechanism_title
      joiningMechanismDescription: joining_mechanism_description
    }

    aboutUs: about_page {
      title
      summary
      content
      vision
      mission
      goals
    }

    news(filter: { status: { _eq: "published" } }, sort: ["-date_created"]) {
      id
      title
      date
      content
      image {
        id
        filename: filename_download
        title
      }
    }
  }
`;
