<section id="about-us">
  <div class="container">
    <div class="row">
      <div class="col-md-12 ps-md-8">
        <div class="h6 fw-bold text-primary">
          {{ landingHero.joiningMechanismTitle }}
        </div>
        <div [innerHTML]="landingHero.joiningMechanismDescription" class="h4 fw-bold" style="line-height: inherit;"></div>
      </div>
    </div>
  </div>
</section>
