import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  Initiative,
  InitiativePage,
  InitiativePageData,
} from "src/app/interfaces/initiative-page";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-initiative-page",
  templateUrl: "./initiative-page.component.html",
  styleUrls: ["./initiative-page.component.scss"],
})
export class InitiativePageComponent implements OnInit {
  constructor(private route: ActivatedRoute, private http: HttpClient) {}
  initiativePage!: InitiativePage;
  latestInitiative!: Initiative;
  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as InitiativePageData;
    this.initiativePage = page.meta;
    this.getInitiatives();
  }
  arrangeWords = [
    {
      index: 0,
      word: "أولاً",
    },
    {
      index: 1,
      word: "ثانياً",
    },
    {
      index: 2,
      word: "ثالثاً",
    },
    {
      index: 3,
      word: "رابعاً",
    },
    {
      index: 4,
      word: "خامساً",
    },
    {
      index: 5,
      word: "سادساً",
    },
    {
      index: 6,
      word: "سابعاً",
    },
    {
      index: 7,
      word: "ثامناً",
    },
    {
      index: 8,
      word: "تاسعاً",
    },
    {
      index: 9,
      word: "عاشراً",
    },
  ];

  getInitiatives(): void {
    try {
      this.http
        .get<Initiative>(`${environment.appServerURI}/initiatives`)
        .subscribe((data: Initiative) => {
          this.latestInitiative = data;
        });
    } catch (error) {}
  }
}
