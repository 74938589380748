import { Component, Input } from "@angular/core";
import { ReplaySubject } from "rxjs";
import {
  LandingCountries,
  LandingSchool,
} from "src/app/interfaces/landing-page";
import { CMSService } from "src/app/services/cms.service";
import { CountrySchools, ModalService } from "src/app/services/modal.service";
@Component({
  selector: "app-landing-friends-schools",
  templateUrl: "./landing-friends-schools.component.html",
  styleUrls: ["./landing-friends-schools.component.scss"],
})
export class LandingFriendsSchoolsComponent {
  @Input() countries!: LandingCountries[];
  unsubscribe$ = new ReplaySubject(1);

  constructor(
    private modalService: ModalService,
    private cmsService: CMSService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  openModal(country: any) {
    this.cmsService.getCountrySchoolsById(country.id).subscribe({
      next: (schools: LandingSchool[]) => {
        let countrySchools: CountrySchools = {
          countryName: country.nameAr,
          logo: country.logo,
          schools: schools,
        };
        this.modalService.setCountrySchools(countrySchools); //get schools by countryid
        this.modalService.setModalState(true);
      },
    });
  }
}
