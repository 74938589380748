import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ReplaySubject, takeUntil } from "rxjs";
import { Direction } from "src/app/enums/direction";
import { LandingNews } from "src/app/interfaces/landing-page";
import { LocalizationService } from "src/app/services/localization.service";

@Component({
  selector: "app-landing-news-activities",
  templateUrl: "./landing-news-activities.component.html",
  styleUrls: ["./landing-news-activities.component.scss"],
})
export class LandingNewsActivitiesComponent implements OnInit {
  flickityConfig: Flickity.Options = {
    rightToLeft: true,
    cellAlign: "right",
    wrapAround: true,
    pageDots: false,
    autoPlay: true,
  };

  @Input() news!: LandingNews[];


  unsubscribe$ = new ReplaySubject(1);

  constructor(
    private localizationService: LocalizationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.localizationService
      .getLocale()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((locale) => {
        this.flickityConfig.rightToLeft =
          locale.direction === Direction.RIGHT_TO_LEFT;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  goToNewsDetails(singleNews: LandingNews) {
    this.router.navigate([`/news/${singleNews.id}/`]);
  }
}
