import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, map } from "rxjs";
import { Apollo } from "apollo-angular";
import { InitiativeResultPageData } from "../interfaces/initiative-result-page";
import { GET_INITIATIVE_RESULT_PAGE } from "../queries/initiative-result-page.query";

@Injectable({
  providedIn: "root",
})
export class InitiativeResultPageResolver
  implements Resolve<InitiativeResultPageData>
{
  constructor(private apollo: Apollo) {}

  resolve(): Observable<InitiativeResultPageData> {
    return this.apollo
      .query({
        query: GET_INITIATIVE_RESULT_PAGE,
      })
      .pipe(
        map((result): InitiativeResultPageData => {
          const { page: meta, ...page } = result.data;
          return {
            title: "أخبار وفعاليات",
            hero: {
              title: meta.title,
              image: meta.image,
            },
            data: page,
            meta,
          };
        })
      );
  }
}
