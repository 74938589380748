<app-page>
  <page-header></page-header>
  <page-content>
    <div
      *ngIf="latestInitiative && latestInitiative.results.length > 0"
      id="single-news"
      class="my-5"
    >
      <div class="container">
        <div id="news" class="my-5">
          <div class="container">
            <div class="row mb-5">
              <div class="col-lg-3 col-md-4 mb-3">
                <img
                  src="assets/images/news2.png"
                  class="img-fluid rounded-2 cover d-block"
                  style="aspect-ratio: 1"
                  alt=""
                />
              </div>
              <div class="col">
                <p class="small text-light-gray">
                  {{ latestInitiative.startDate }}
                </p>
                <p class="h6 fw-bold">نتائج {{ latestInitiative.name }}</p>

                <p class="mt-4 ms-2 ps-4">
                  تمت لجنة تقويم مسابقة المبادرات للمدارس الصديقة والمنتسبة
                  إليها – الدورة السابعة تقييم المبادرات المقدمة عبر موقع
                  المدارس الصديقة . وبعد أن دققت اللجنة في المبادرات المرشحة وفي
                  تقارير محكميها أسفر التقويم عن مايلي :
                </p>

                <ul class="ps-5">
                  <li>
                    عدد المدارس المتقدمة للمسابقة:
                    {{ totalAppliedSchoolsNumber }}
                  </li>

                  <li>عدد المبادرات المقدمة: {{ totalApplicationsNumber }}</li>
                  <li>عدد المبادرات الفائزة: {{ passedApplicationsNumber }}</li>
                  <li>علماً بأن إجراءات التقويم كانت على النحو التالي :</li>

                  <ol class="ps-5">
                    <li>
                      التقديم كان من ابتداءً من تاريخ:
                      {{ latestInitiative.startDate }}
                    </li>
                    <li>
                      انتهاء التقديم
                      {{ latestInitiative.endDate }}
                    </li>
                  </ol>
                </ul>

                <h5>وبناءاً على تقارير اللجنة تقرر فوز ما يلي :</h5>
              </div>
            </div>

            <!-- مبادرات المدارس -->
            <div
              *ngIf="schoolsApps && schoolsApps.length > 0"
              class="table-responsive fw-bold"
            >
              <table class="table table-striped align-middle table-lg">
                <thead class="bg-opacity-25 bg-primary">
                  <tr>
                    <th colspan="3" class="text-center py-2">
                      أولاً : مبادرات المدارس
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="fw-bold" style="background-color: #eaf8f1">
                    <td>عنوان المبادرة</td>
                    <td class="text-center">اسم المدرسة</td>
                    <td class="text-center">الدولة</td>
                  </tr>
                  <tr *ngFor="let schoolsApp of schoolsApps; index as i">
                    <td>{{ i + 1 }} - {{ schoolsApp.title }}</td>
                    <td class="text-center">
                      {{ schoolsApp.school }}
                      <br />
                      مدير المدرسة: {{ schoolsApp.manager }}
                    </td>
                    <td class="text-center">
                      {{ schoolsApp.country }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--  -->

            <!-- مبادرات المعلمين -->
            <div
              *ngIf="teachersApps && teachersApps.length > 0"
              class="table-responsive fw-bold"
            >
              <table class="table table-striped align-middle table-lg">
                <thead class="bg-opacity-25 bg-primary">
                  <tr>
                    <th colspan="3" class="text-center py-2">
                      ثانيا : مبادرات المعلمين
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="fw-bold" style="background-color: #eaf8f1">
                    <td>عنوان المبادرة</td>
                    <td class="text-center">اسم المدرسة</td>
                    <td class="text-center">الدولة</td>
                  </tr>
                  <tr *ngFor="let teachersApp of teachersApps; index as p">
                    <td>{{ p + 1 }} - {{ teachersApp.title }}</td>
                    <td class="text-center">
                      {{ teachersApp.school }}
                      <br />
                      المعلم: {{ teachersApp.teachers }}
                    </td>
                    <td class="text-center">
                      {{ teachersApp.country }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--  -->

            <!-- مبادرات الطلاب -->
            <div
              *ngIf="studentsApps && studentsApps.length > 0"
              class="table-responsive fw-bold"
            >
              <table class="table table-striped align-middle table-lg">
                <thead class="bg-opacity-25 bg-primary">
                  <tr>
                    <th colspan="3" class="text-center py-2">
                      ثالثاً : مبادرات الطلاب
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="fw-bold" style="background-color: #eaf8f1">
                    <td>عنوان المبادرة</td>
                    <td class="text-center">اسم المدرسة</td>
                    <td class="text-center">الدولة</td>
                  </tr>
                  <tr *ngFor="let studentApp of studentsApps; index as d">
                    <td>{{ d + 1 }} - {{ studentApp.title }}</td>
                    <td class="text-center">
                      {{ studentApp.school.name }}
                      <br />
                      <span *ngIf="studentApp.students.split('\n').length > 1">
                        أسماء الطلاب:
                        <span
                          *ngFor="
                            let student of studentApp.students.split('\n');
                            index as g
                          "
                        >
                          <div class="text-center">
                            {{ g + 1 }} - {{ student }}
                          </div>
                        </span>
                      </span>
                      <span
                        *ngIf="studentApp.students.split('\n').length === 1"
                      >
                        اسم الطالب: {{ studentApp.students.split("\n") }}
                      </span>
                    </td>
                    <td class="text-center">المملكة العربية السعودية</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--  -->
          </div>
        </div>
      </div>
    </div>

    <div
      style="min-height: 200px"
      *ngIf="latestInitiative && latestInitiative.results.length === 0"
    >
      <div class="alert alert-success w-25 mx-auto text-center mt-8" role="alert">
        <h4 class="alert-heading">لا توجد نتائج حالية</h4>
        <p></p>
        <p class="mb-0"></p>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
