import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  LandingAboutUs,
  LandingCountries,
  LandingLatestInitiative,
  LandingNews,
  LandingPage,
  LandingPageData,
  LandingSubscriptions,
} from "src/app/interfaces/landing-page";
import AOS from "aos";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
})
export class LandingPageComponent implements OnInit {
  landingHero!: LandingPage;
  aboutUs!: LandingAboutUs;
  countries!: LandingCountries[];
  initiatives!: LandingLatestInitiative[];
  news!: LandingNews[];
  subscriptions!: LandingSubscriptions[];
  constructor(private route: ActivatedRoute, private http: HttpClient) {}

  ngOnInit(): void {
    this.getCountries();
    const { meta, ...page } = this.route.snapshot.data[
      "page"
    ] as LandingPageData;
    this.landingHero = meta;
    this.aboutUs = page.data.aboutUs;

    this.news = page.data.news;

    AOS.init({
      // disable: "mobile"
      once: true,
    });
    AOS.refresh(); //refresh method is called on window resize and so on, as it doesn't require to build new store with AOS elements and should be as light as possible.
  }

  getCountries(): void {
    try {
      this.http
        .get<any[]>(`${environment.appServerURI}/countries`)
        .subscribe((data: any) => {
          this.countries = data.data;
        });
    } catch (error) {}
  }
}
