import { gql } from "apollo-angular";
import { InitiativeResultPage } from "../interfaces/initiative-result-page";

export const GET_INITIATIVE_RESULT_PAGE = gql<
  {
    page: InitiativeResultPage;
  },
  void
>`
  query {
    page: news_page {
      id
      title
      image: header_image {
        id
        title
        filename: filename_download
      }
    }
  }
`;
