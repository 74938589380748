<!-- Modal -->
<div
  class="modal fade"
  id="schoolModal"
  tabindex="1"
  aria-labelledby="schoolModalLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered"
  >
    <div class="modal-content">
      <div class="modal-header mt-2">
        <div class="flag-wrapper">
          <img src="assets/images/flag-wrapper.svg" alt="" class="mask" />
          <img
            [src]="countrySchools.logo"
            [alt]="countrySchools.countryName"
            class="flag"
          />
        </div>

        <h1
          class="modal-title position-relative fs-5 ps-5 fw-bold"
          id="schoolModalLabel"
        >
          {{ countrySchools.countryName }}
        </h1>
        <button
          (click)="closeModal()"
          type="button"
          class="btn btn-link"
          aria-label="Close"
        >
          <img src="assets/images/close-circle.svg" alt="" />
        </button>
      </div>
      <div class="modal-body">
        <div
          *ngIf="countrySchools.schools.length === 0"
          class="no-data text-center fw-bold"
        >
          لا توجد مدارس.
        </div>
        <div *ngFor="let school of countrySchools.schools">
          <h5 class="fw-bold text-primary mt-4">
            {{ school.name }}
          </h5>
          <table class="table table-borderless align-middle">
            <tr>
              <td><i class="fa-solid fa-phone fa-flip-horizontal fs-5"></i></td>
              <td>هاتف</td>
              <td>:</td>
              <td>{{ school.phone }}</td>
              <td class="text-end ml-5 pe-5" width="" rowspan="3">
                <a
                  [href]="school.website"
                  target="_blank"
                  class="btn btn-gradient rounded-pill text-white"
                  >زيارة الموقع</a
                >
              </td>
            </tr>
            <tr>
              <td><i class="fa-solid fa-globe fa-flip-horizontal fs-5"></i></td>
              <td>الموقع الإلكتروني</td>
              <td>:</td>
              <td>{{ school.website }}</td>
            </tr>
            <tr>
              <td>
                <i class="fa-solid fa-envelope fa-flip-horizontal fs-5"></i>
              </td>
              <td>البريد الإلكتروني</td>
              <td>:</td>
              <td>{{ school.email }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
