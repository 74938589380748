/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {DOCUMENT} from "@angular/common";
import {Inject, Injectable, NgZone} from "@angular/core";

export type ExtendedWindow = (Window & typeof globalThis) & {[property: string]: any};

@Injectable({
  providedIn: "root",
})
export class WindowService {
  constructor(private readonly ngZone: NgZone, @Inject(DOCUMENT) private document: Document) {}

  get isBrowser() {
    return true;
  }

  get scrollY() {
    return window.scrollY;
  }

  getWindow(): ExtendedWindow | null {
    return this.document.defaultView;
  }

  getLocalStorage() {
    return window.localStorage;
  }

  run<T = unknown>(fn: (...args: any[]) => T) {
    return fn();
  }

  runInsideAngularZone<T = unknown>(fn: (...args: any[]) => T) {
    this.ngZone.run(fn);
  }

  runOutsideAngularZone<T = unknown>(fn: (...args: any[]) => T) {
    this.ngZone.runOutsideAngular(fn);
  }

  scrollTo(x: number, y: number) {
    return window.scrollTo(x, y);
  }

  removeTags(content: string) {
    const element = this.document.createElement("div");
    element.innerHTML = content;
    return element.innerText;
  }

  getFriendlyDescription(content: string) {
    if (!content) return undefined;
    return this.removeTags(content.split(".")[0]);
  }
}

@Injectable({
  providedIn: "root",
})
export class ServerWindowService {
  get isBrowser() {
    return false;
  }

  get scrollY() {
    return 0;
  }

  getWindow(): ExtendedWindow | null {
    return null;
  }

  getLocalStorage() {
    return null;
  }

  run<T = unknown>(fn: (...args: any[]) => T) {
    return;
  }

  runInsideAngularZone<T = unknown>(fn: (...args: any[]) => T) {
    return;
  }

  runOutsideAngularZone<T = unknown>(fn: (...args: any[]) => T) {
    return;
  }

  scrollTo(x: number, y: number) {
    return;
  }

  removeTags(content: string) {
    return content;
  }

  getFriendlyDescription(content: string) {
    if (!content) return undefined;
    return this.removeTags(content.split(".")[0]);
  }
}
