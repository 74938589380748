import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AboutUsPage, AboutUsPageData } from "src/app/interfaces/about-us-page";

@Component({
  selector: "app-about-us-page",
  templateUrl: "./about-us-page.component.html",
  styleUrls: ["./about-us-page.component.scss"],
})
export class AboutUsPageComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  aboutUsData!: AboutUsPage;
  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as AboutUsPageData;
    this.aboutUsData = page.meta;
  }
}
